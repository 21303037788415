<template>
  <section class="bannerBox">
	  <!-- <div class="bannerContent">
		  <h2>인기 게임 쿠폰</h2>
		  <p>더 많은 게임쿠폰 일괄보기</p>
		  <a>모두 선택하여 구매</a>
	  </div> -->
  </section>
  <section class="richText bg1">
  	  <div class="richTextContent">
  		  <h2>귀하는 여기에서  최고의 혜택을 누릴 수 있습니다</h2>
  		  <!-- <p>이것은 인기 게임 교환 코드의 스토어입니다. 관심 있다면 100원만 있으면 게임 내 혜태 아이템을 획득할 수 있습니다.</p> -->
  	  </div>
  </section>
  <section class="bg1">
	  <div class="listBox">
		  <h2 class="title">할인 상품</h2>
		  <div class="listItem">
			  <ul>
				<template v-for="(item,index) in goodList" :key="index">
					<li class="item">
						<div class="imgBox">
							<img :src="item.img" />
							<!-- <span class="tag">강시</span> -->
						</div>
						<div class="desBox">
							<h3 class="title"><router-link :to="'/products/detail/'+item.id">{{item.title}}</router-link></h3>
							<p class="price">
								<span class="original">${{item.originalPrice}} USD</span>
								<span class="onsale">${{item.presentPrice}} USD</span>
							</p>
						</div>
					</li>
				</template>
			  </ul>
		  </div>
		  
		  <swiper
		    :effect="'coverflow'"
			:grabCursor="true"
			:centeredSlides="true"
			:slidesPerView="'auto'"
			:coverflowEffect="{
			  rotate: 50,
			  stretch: 0,
			  depth: 100,
			  modifier: 1,
			  slideShadows: false,
			}"
			:pagination="true" 
		  	:style="{
		  	  '--swiper-pagination-color': '#000'
		  	}"
		    class="mySwiper"
		    >
		    <swiper-slide v-for="(item,index) in adList" :key="index">
		  		<div class="ad">
		  			<img :src="item.url">
		  		</div>
		  	</swiper-slide>
		  </swiper>
	  </div>
  </section>

</template>

<script setup>
import { ref, onMounted } from "vue";
import { getGoodList } from '@/api/index'
import { Swiper, SwiperSlide } from 'swiper/vue';

import SwiperCore, { EffectCoverflow,Pagination,Navigation } from 'swiper/core';
SwiperCore.use([EffectCoverflow,Pagination,Navigation]);

import "swiper/components/effect-coverflow";

const adList = ref([
	{ url: require('@/assets/images/ad1.png') },
	{ url: require('@/assets/images/ad2.png') },
	{ url: require('@/assets/images/ad3.png') },
	{ url: require('@/assets/images/ad4.png') }
])

const goodList = ref([])

onMounted(()=>{
	getGoodList().then((res)=>{
		goodList.value = res.rows
	})
})

</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
.bannerBox{
	width: 100%;min-height: 36rem;overflow: hidden;
	background: url('~@/assets/images/banner.jpg') no-repeat center;
	background-size: 100%;position: relative;z-index: 1;
	&::after{
		content: '';display: block;position: absolute;top: 0;left: 0;
		width: 100%;height: 100%;background: rgba(18,18,18,0.2);z-index: 111;
	}
	.bannerContent{
		position: absolute;color: #fff;text-align: center;z-index: 1111;
		left: 50%;bottom: 13rem;transform: translate(-50%,0);
		h2{
			font-size: 5.2rem;font-weight: 400;
		}
		p{
			margin-top: 1rem;font-size: 1.6rem;
		}
		a{
			display: inline-block;padding: 1.5rem 3rem;border: 1px solid #fff;color: #fff;
			letter-spacing: 0.1rem;margin-top: 2rem;box-sizing: border-box;font-size: 1.6rem;
			transition: box-shadow 0.1s ease;
			&:hover{
				box-shadow: 0 0 2px rgb(255,255,255);
			}
		}
	}
}
.richText{
	padding: 40px;box-sizing: border-box;padding-bottom: 0;
	.richTextContent{
		width: 100%;text-align: center;
		padding: 0 5rem;margin: 0 auto;
		h2{
			font-size: 2.4rem;font-weight: 400;
		}
		p{
			margin-top: 2rem;color: rgba(18,18,18,0.75);font-size: 1.6rem;
		}
	}
}
.mySwiper{
	margin-top: 3rem;
	.ad{
		position: relative;width: 100%;height: 170px;overflow: hidden;
		img{
			height: 100%;position: absolute;
			top: 0;left: 50%;transform: translate(-50%,0);
		}
	}
}
</style>
